import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/css/style.css'
Vue.config.productionTip = false
import 'lib-flexible/flexible.js'
import '@/utils/rem.js'

import {
  checkPower,
  formatDate,
  deepClone,
  jumpUrl,
  goBack,
  randomString,
} from "./utils/utils";
import {get, post} from "./utils/http";
import cache from "./utils/cache";
import dataV from '@jiaminghi/data-view'
const utils = require("./utils/utils");
Vue.prototype.$funcs = { checkPower, formatDate, deepClone,randomString,goBack,jumpUrl};
Vue.config.productionTip = false;
Vue.prototype.$cache = cache;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$utils = utils;

import VueParticles from 'vue-particles';
Vue.use(VueParticles);
Vue.use(dataV);
import jayAlert from '@/components/JayAlert/index'
import jayPrompt from '@/components/JayPrompt/index'
import jayMessage from '@/components/JayMessage/index'
Vue.use(jayAlert)
Vue.use(jayPrompt)
Vue.prototype.$jayMessage = jayMessage;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
