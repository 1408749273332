import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Router from 'vue-router'
import * as baseRouter from "@/router/router.screen";
import * as screenRouter from "@/router/router.screen";

Vue.use(VueRouter)
let _router = [];
switch (process.env.VUE_APP_USER_SYSTEM) {
  case "base":
    _router = baseRouter;
    break;
  case "screen":
    _router = screenRouter;
    break;
  default:
    _router = baseRouter;
}
const routes: Array<RouteConfig> = _router;
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  // @ts-ignore
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_ROOT_BASE_URL,
  routes
})

export default router
