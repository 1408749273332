const router=[
    {
        path: '/street',
        name: 'screen/street',
        component: () => import( '../views/Screen/Street.vue'),
        meta: {
            title: "数据大屏-街道",
            isFresh: false,
        }
    }, {
        path: '/cctv',
        name: 'screen/cctv',
        component: () => import( '../views/Screen/CCTV.vue'),
        meta: {
            title: "数据大屏-监控",
            isFresh: false,
        }
    },{
        path: '/login',
        name: 'screen/login',
        component: () => import( '../views/Screen/Common/Login.vue'),
        meta: {
            title: "数据大屏-登陆",
            isFresh: false,
        }
    },{
        path: '*',
        name: '/login',
        component: () => import( '../views/Screen/Common/Login.vue'),
        meta: {
            title: "数据大屏-登陆",
            isFresh: false,
        }
    },
]

module.exports = router;
