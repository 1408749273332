import store from '@/store/index'
import router from "../router";
import Config from '@/utils/config';
// import {Message} from 'element-ui';
import {post} from '@/utils/http';
import axios from 'axios' // 引用axios
// import OSS from 'ali-oss';
// import VueClipboard from 'vue-clipboard2'
import {upload_post} from "./http";

/**
 * 检查权限方法
 * @param power_list
 * @param power_name
 * @returns boolean
 */

export function checkPower(power_list, power_name) {
    return power_list.indexOf(power_name) !== -1;
    // return power_list.indexOf(power_name) === -1;
}

export function formatThousands(value, num) {
    num = num > 0 && num <= 20 ? num : 2;
    value = parseFloat((value + "").replace(/[^\d\.-]/g, "")).toFixed(num) + ""; //将金额转成比如 123.45的字符串
    var valueArr = value.split(".")[0].split("").reverse() //将字符串的数变成数组
    const valueFloat = value.split(".")[1]; // 取到 小数点后的值
    let valueString = "";
    for (let i = 0; i < valueArr.length; i++) {
        valueString += valueArr[i] + ((i + 1) % 3 == 0 && (i + 1) != valueArr.length ? "," : ""); //循环 取数值并在每三位加个','
    }
    //拼接上小数位
    return valueString.split("").reverse().join("") + "." + valueFloat
}

export function delArrByIndex(arr, index) {
    for (var i = index, len = arr.length - 1; i < len; i++)
        arr[i] = arr[i + 1];
    arr.length = len;
    // console.log(arr);//=>[0, 1, 2, 4, 5]
    return arr;
}

export function randomString(len) {
    len = len || 32;
    var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    var maxPos = $chars.length;
    var pwd = '';
    for (var i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

function covertUTCnTo0Time(str) {
    return new Date(str).getTime()
}

export function formatDate(_date, format = 'Y-m-d') {
    if ((_date + '').length == 10) {
        _date * 1000;
    }
    var date = new Date((covertUTCnTo0Time(_date)));
    var YY = date.getFullYear();
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    let str = '';
    switch (format) {
        case "Y-m-d":
            str = YY + '-' + MM + '-' + DD;
            break;
        case "Y年m月d日":
            str = YY + "年" + MM + '月' + DD + "日";
            break;
        case "Y/m/d":
            str = YY + "/" + MM + '/' + DD;
            break;
        case "Y/m/d H:i:s":
            str = YY + "/" + MM + '/' + DD + '  ' + hh + ":" + mm + ":" + ss;
            break;
        case "Y年m月d日 H:i:s":
            str = YY + "年" + MM + '月' + DD + "日" + ' ' + hh + ":" + mm + ":" + ss;
            break;
        case "Y-m-d H:i:s":
            str = YY + "-" + MM + '-' + DD + ' ' + hh + ":" + mm + ":" + ss;
            break;
        case "Y,m,d,H,i,s":
            str = YY + "-" + (MM) + '-' + DD + ' ' + hh + ":" + mm + ":" + ss;
            break;
        case "m-d H:i:s":
            str = MM + '-' + DD + ' ' + hh + ":" + mm + ":" + ss;
            break;
        case "m-d H:i":
            str = MM + '-' + DD + ' ' + hh + ":" + mm;
            break
        case "Y-m":
            str = YY + '-' + MM;
            break;
        default:
            str = YY + '-' + MM + '-' + DD + '  ' + hh + ":" + mm + ":" + ss;
    }
    return str;
}

export function removeTab(targetName) {
    if (targetName === "/home") {
        return;
    }
    let tabs;
    let data = store.getters.editableTabs;
    tabs = typeof data === 'string' ? JSON.parse(data) : data;
    //tabs = typeof this.editableTabs ==='string'? JSON.parse(this.editableTabs):this.editableTabs;
    let activeName = store.getters.editableTabsValue;
    if (activeName === targetName) {
        // 设置当前激活的路由
        tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
                let nextTab = tabs[index + 1];
                let preTab = tabs[index - 1];
                if (nextTab) {
                    activeName = nextTab.name;
                } else if (preTab) {
                    activeName = preTab.name;
                } else {
                    activeName = "/home";
                }
            }
        });
    }
    let tab1;
    tab1 = tabs.filter(tab => tab.name !== targetName);
    store.commit('addTab', tab1);
    store.commit('setTabName', activeName);
    router.push({path: activeName});
}

// export function uploadFile(file) {
//     let file_name = file.name;
//     let ext = file_name.split('.')[1].toLowerCase();
//     const ACCEPT_EXT = Config.upload.pic_ext;
//     if (ACCEPT_EXT.indexOf(ext) == -1) {
//         Message(
//             '文件上传失败，请上传' + ACCEPT_EXT + '文件', '提示'
//         )
//         return false;
//     }
//     if (file.size >= Config.upload.pic_size) {
//         Message(
//             '文件上传失败，文件大小限制为' + Config.upload.pic_size, '提示'
//         )
//         return false;
//     }
//     return new Promise(function (resolve, reject) {
//
//         let config = {
//             headers: {
//                 content_type: 'multipart/form-data'
//             }
//         }
//         upload_post('', file, config).then(request => {
//             resolve(request)
//         }).catch(err => {
//             reject(err)
//         });
//     })
// }

export function jumpUrl(url) {
    router.push(url)
}

// export function copySuccess() {
//     Message({
//         message: '复制成功！', type: 'success'
//     });
// }
//
// export function copyError() {
//     Message({
//         message: '复制失败！', type: 'danger'
//     });
// }

// export function copyStr(str) {
//     let clipboard = new VueClipboard('.tag', {
//         text: function () {
//             return str
//         }
//     })
//     clipboard.on('success', e => {
//         Message({
//             message: '复制成功！', type: 'success'
//         });
//         // 释放内存
//         clipboard.destroy()
//     })
// }

export function deepClone(initalObj, finalObj) {
    var obj = finalObj || {};
    for (var i in initalObj) {
        var prop = initalObj[i];        // 避免相互引用对象导致死循环，如initalObj.a = initalObj的情况
        if (prop === obj) {
            continue;
        }
        if (typeof prop === 'object') {
            obj[i] = (prop.constructor === Array) ? [] : Object.create(prop);
        } else {
            obj[i] = prop;
        }
    }
    return obj;
}

export function goBack() {
    history.go(-1);
}

export function numberToFixed(value, num) {
    value = +(value);
    if (value == null || value == '' || isNaN(value)) return 0;
    return value.toFixed(num);
}

/**
 * 价格格式化
 * @param value 价格
 * @returns {string}
 */
export function priceFormat(value) {
    if (value === undefined || isNaN(value)) return '0.00';
    // 使用toFixed(2)保留两位小数，然后使用正则表达式添加千位分隔符
    return parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function secToTime(s) {
    let t = 0;
    if (s > -1) {
        var hour = Math.floor(s / 3600);
        var min = Math.floor(s / 60) % 60;
        var sec = s % 60;
        if (hour < 10) {
            t = '0' + hour + ":";
        } else {
            t = hour + ":";
        }

        if (min < 10) {
            t += "0";
        }
        t += min + ":";
        if (sec < 10) {
            t += "0";
        }
        t += sec.toFixed(2);
    }
    return t;
}

export function secondToDate(second) {
    if (!second) {
        return 0;
    }
    const time = new Array(0, 0, 0, 0, 0);
    if (second >= 365 * 24 * 3600) {
        time[0] = parseInt(second / (365 * 24 * 3600));
        second %= 365 * 24 * 3600;
    }
    if (second >= 24 * 3600) {
        time[1] = parseInt(second / (24 * 3600));
        second %= 24 * 3600;
    }
    if (second >= 3600) {
        time[2] = parseInt(second / 3600);
        second %= 3600;
    }
    if (second >= 60) {
        time[3] = parseInt(second / 60);
        second %= 60;
    }
    if (second > 0) {
        time[4] = second;
    }
    return time;
}

/**
 * 秒数格式化（xx小时xx分xx秒）
 * @param d 秒
 * @returns {{hours: number, seconds: number, minutes: number}}
 */
export function secondsToHms(d) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    return {
        hours: h,
        minutes: m,
        seconds: s
    };
}