<template>
  <transition name="msgbox">
    <div v-if="show" class="msgbox-container" >
          <header v-if="title!=''">{{title}}</header>
          <div class="content-body" style="text-align: center">
            <div v-html="content" :class="className"></div>
          </div>
          <footer  :class="{'flex-around':cancel,'flex-center':!cancel}">
            <a v-if="cancel" href="javascript:;" @click="cancelBtn" class="button cancel">{{cancel}}</a>
            <a href="javascript:;" @click="successBtn" class="button confirm">{{confirm}}</a>
          </footer>
        </div>
  </transition>
</template>
<script>
export default {
  name: "JayDialog",
  data(){
    return {
      show: false,
      title: '',
      content: '',
      confirm: '确定',
      cancel:'',
      className:''
    }
  },
  watch:{
    show (val) {
      if (val) {
        if(!this.hasClass(document.body,"pop-mask")){
          this.addClass(document.body,"pop-mask");
        }
      }else{
        this.removeClass(document.body,"pop-mask");
      }
    }
  },
  beforeMount () {
    //如果已经存在，则阻止出现第二次
    let node = document.querySelector('.msgbox-container')
    if (node && node.parentNode) {
      node.parentNode.removeChild(node)
    }
  },
  methods: {
    successBtn () {
      this.show = false;
    },
    cancelBtn () {
      this.show = false;
    },
    addClass(obj, cls){
      var obj_class = obj.className,
          blank = (obj_class != '') ? ' ' : '';
      var added = obj_class + blank + cls;
      obj.className = added;
    },
    removeClass(obj, cls){
      var obj_class = ' '+obj.className+' ';
      obj_class = obj_class.replace(/(\s+)/gi, ' ');
      var removed = obj_class.replace(' '+cls+' ', ' ');
      removed = removed.replace(/(^\s+)|(\s+$)/g, '');
      obj.className = removed;//替换原来的 class.
    },
    hasClass(obj, cls){
      var obj_class = obj.className,
          obj_class_lst = obj_class.split(/\s+/);
      var x = 0;
      for(x in obj_class_lst) {
        if(obj_class_lst[x] == cls) {
          return true;
        }
      }
      return false;
    }
  },

}
</script>

<style lang="scss" scoped>
.msgbox-container{
  position: fixed;
  top:50%;
  left:50%;
  width: 30%;
  background: rgba(70, 52, 90, 1);
  border: 1px solid #C598FF;
  box-sizing: border-box;
  transform:translate(-50%,-50%) scale(1, 1);
  z-index: 9999;
  header{
    margin: 0;
    padding: 12px 0;
    font-family: Alibaba;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    background: rgba(70, 52, 90, 0.4);
    border-width: 0;
  }
  .content-body{
    font-size: 1rem;
    margin: 10px 22px;
    line-height: 2;
    max-height: 20rem;
    overflow-y: auto;
    color: #fff;
    div{
      padding: 0 1rem;
      //text-align: justify;
      word-break: break-all;
    }
  }
  footer {
    width:76%;
    height: 40px;
    text-align: center;
    border-width: 0;
    //border-top: 1px solid #ccc;
    overflow: hidden;
    background: transparent;
    margin: 24px auto 24px;
    //border-radius: 0 0 0.8rem 0.8rem;
    .button{
      width: 30%;
      height: 36px;
      //box-sizing: border-box;
      font-family: Alibaba;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;
      display: block;
    }
    .confirm{
      background: rgba(74, 255, 222, 0.3);
      border: 1px solid #4AFFF4;
      box-sizing: border-box;
      color: #B6FFFB;
    }
    .cancel{
      background: rgba(231, 66, 66, 0.3);
      border: 1px solid #E74242;
      box-sizing: border-box;
      color: #FFFFFF;
    }
    .confirm:hover{
      background: #4AFFDE;
      border: 1px solid #4AFFDE;
      box-sizing: border-box;
      color: #FFFFFF;
    }
    .cancel:hover{
      background: #E74242;
      border: 1px solid #E74242;
      box-sizing: border-box;
      color: #FFFFFF;
    }
    .button:first-child:nth-last-child(1) {
      width: 30%;
      height: 36px;
    }
    .button:first-child:nth-last-child(2) ~ .button {
      width: 30%;
      height: 36px;
    }
  }
}
.msgbox-enter,.msgbox-leave-to{
  -webkit-transform:  translate(-50%,-50%) scale(0,0);
}
.msgbox-enter-active,.msgbox-leave-active{
  -webkit-transition: all .3s;
  transition: all .3s;
}
.msgbox-enter-to,.msgbox-leave{
  -webkit-transform:  translate(-50%,-50%) scale(1,1);
}

</style>