// 基准大小

// 设置 rem 函数
function setRem () {
    // const baseSize = 16
    // // 当前页面宽度相对于 1920 宽的缩放比例，可根据自己需要修改。
    // // const scale = document.documentElement.clientWidth / 1920;
    // const scale = document.documentElement.clientWidth / 3656;
    // // 设置页面根节点字体大小, 字体大小最小为12
    // let fontSize = (baseSize * Math.min(scale, 2))>12 ? (baseSize * Math.min(scale, 2)): 12
    // console.log(fontSize);
    // document.documentElement.style.fontSize = fontSize + 'px'



    // 基准大小
    // let widthScale = window.innerWidth // 当前窗口的宽度
    // let heightScale = window.innerHeight // 当前窗口的高度
    let widthScale = document.documentElement.clientWidth // 当前窗口的宽度
    let heightScale = document.documentElement.clientHeight // 当前窗口的宽度
    const baseSize = 16 //浏览器默认字体大小16px
    const baseScale = baseSize / 1920 // 针对PC端，1920的设计图。 移动端的对应调整为750或者375

    //尺寸换算-根据宽高比率取最小缩放比-目前不考虑高度
    // const comparedHeight = (widthScale * 1080) / 1920
    // if (heightScale < comparedHeight) {
    //   widthScale = (heightScale * 1920) / 1080
    // }
    // 计算实际的rem值,得到该宽度下的相应font-size值,并赋予给html的font-size,
    const rem = widthScale * baseScale;
    // console.log("width="+widthScale);
    // console.log("height="+heightScale);
    // console.log("rem="+rem);
    document.documentElement.style.fontSize = rem + 'px'
}
//初始化
setRem()
//改变窗口大小时重新设置 rem,这里最好加上节流
window.onresize = function () {
    // setRem()
    setTimeout(function () {
        setRem()
    },300)
}
